import { createSelector } from "reselect";
import { STORES } from "../stores";
import { LoadingSlice } from "./loading.types";

export const selectLoadingManager = (state: any): LoadingSlice =>
  state[STORES.LOADING_MANAGER];

export const selectIsLoading = createSelector(
  [selectLoadingManager],
  (loadingManager) => loadingManager.isLoading
);
