import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACES } from "../i18n_namespaces";

interface ConfirmationDialogProps {
  openModal: boolean;
  onConfirmation: () => void;
  onCancel: () => void;
  onClose: () => void;
  dialogTitleText: string;
  dialogContentText: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
}
const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  openModal,
  onConfirmation,
  onCancel,
  onClose,
  dialogTitleText,
  dialogContentText,
  cancelButtonText,
  confirmButtonText,
}) => {
  const { t: tCommon } = useTranslation(I18N_NAMESPACES.COMMON);
  if (!cancelButtonText) {
    cancelButtonText = tCommon("Cancel");
  }

  if (!confirmButtonText) {
    confirmButtonText = tCommon("Confirm");
  }

  return (
    <Dialog
      open={openModal}
      onClose={onClose}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {dialogTitleText}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {cancelButtonText}
        </Button>
        <Button onClick={onConfirmation} color="primary">
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
