import React, { useEffect, useState } from "react";

import FooterLink from "./footer-link";
import InternalFooterLink from "./internal-footer-link";
import { Grid, Divider, Typography } from "@material-ui/core";
import {
  DONOTSKIP_EMAILS,
  DONOTSKIP_URLS,
  getMailToUrl,
} from "@arnaud-cortisse/donotskip-core";
import { useTranslation } from "../../../i18n";
import { I18N_NAMESPACES } from "../../i18n_namespaces";
import Router from "next/router";
import ROUTES from "../../../routes";

interface FooterProps {
  siteTitle: string;
}
const Footer: React.FC<FooterProps> = ({ siteTitle = "" }) => {
  const { t: tCommon, i18n } = useTranslation(I18N_NAMESPACES.COMMON);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const onRouteChangeStartCallback = (url: string) => {
      const hide =
        url === ROUTES.PRIVACY_POLICY || url === ROUTES.TERMS_AND_CONDITIONS;
      setHide(hide);
    };

    Router.events.on("routeChangeStart", onRouteChangeStartCallback);

    return () => {
      Router.events.off("routeChangeStart", onRouteChangeStartCallback);
    };
  }, []);

  if (hide) {
    return null;
  }

  return (
    <footer
      style={{
        padding: "1rem",
        backgroundColor: "#fff",
        flexShrink: 0,
      }}
    >
      <Grid container justify="center" spacing={2} direction="column">
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          spacing={2}
          direction="column"
        >
          <Grid item>
            <Typography variant="subtitle2">{tCommon("Contact")}</Typography>
          </Grid>
          <Grid item>
            <FooterLink
              title={DONOTSKIP_EMAILS?.DONOTSKIP_APP_CONTACT}
              href={
                getMailToUrl
                  ? getMailToUrl(DONOTSKIP_EMAILS?.DONOTSKIP_APP_CONTACT)
                  : ""
              }
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography variant="subtitle2">
              {tCommon("Other websites")}
            </Typography>
          </Grid>
          <Grid container item justify="center" spacing={2}>
            <Grid item>
              <FooterLink
                title={tCommon("DoNotSkip Coaches")}
                href={DONOTSKIP_URLS?.DONOTSKIP_COACHES}
              />
            </Grid>
            <Grid item>
              <FooterLink
                title={tCommon("DoNotSkip Programs")}
                href={DONOTSKIP_URLS?.DONOTSKIP_PROGRAMS}
              />
            </Grid>
            <Grid item>
              <FooterLink
                title={tCommon("DoNotSkip App")}
                href={DONOTSKIP_URLS?.DONOTSKIP_APP}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container item xs={12} justify="center" spacing={2}>
          <Grid item>
            <InternalFooterLink
              title={tCommon("Terms and Conditions")}
              href={ROUTES.TERMS_AND_CONDITIONS}
            />
          </Grid>
          <Grid item>
            <InternalFooterLink
              title={tCommon("Privacy Policy")}
              href={ROUTES.PRIVACY_POLICY}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="center" spacing={2}>
          <div style={{ color: "#A0A4A6" }}>
            © {new Date().getFullYear()}
            {` `}
            {siteTitle}
          </div>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
