import React from "react";
import { Box } from "@material-ui/core";
import Footer from "./Footer/footer";

const siteTitle = "DoNotSkip Programs";

interface PageWrapperProps {
  displayFooter?: boolean;
}
const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  displayFooter = true,
}) => {
  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {children}
      {displayFooter && <Footer siteTitle={siteTitle} />}
    </Box>
  );
};

export default PageWrapper;
