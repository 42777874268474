import React, { useCallback } from "react";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useTranslation } from "react-i18next";
import { Languages } from "../Languages";
import { withTranslation } from "../../i18n";
import { useDispatch } from "react-redux";
import { actionChangeLanguage } from "../redux/language/language.actions";

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const changeLanguageCallback = useCallback(
    (languageId) => {
      i18n.changeLanguage(languageId);
      dispatch(actionChangeLanguage({ languageId }));
    },
    [i18n]
  );

  return (
    // <FormControl color="primary">
    <Select
      color="primary"
      displayEmpty
      value={i18n.language}
      input={<Input id="select-multiple" />}
      style={{ color: "#fff" }}
      IconComponent={() => <ArrowDropDownIcon />}
      renderValue={(value: unknown) => {
        return (
          <div
            style={{
              color: "#fff",
            }}
          >
            {value as string}
          </div>
        );
      }}
    >
      {Object.entries(Languages.supportedLanguages.allIds).map(
        ([_, languageId]) => {
          const language = Languages.supportedLanguages.byId[languageId];
          return (
            <MenuItem
              key={language.id}
              value={language.id}
              button
              onClick={() => changeLanguageCallback(language.id)}
            >
              {language.friendlyName}
            </MenuItem>
          );
        }
      )}
    </Select>
    // </FormControl>
  );
};

export default LanguageSelector;
