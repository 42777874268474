export enum ROUTES {
  HOME = "/",
  PROGRAMS_LANDING_PAGE = "/program-landing-pages",
  ABOUT = "/about",
  LOGIN = "/login",
  CHECKOUT = "/checkouts",
  SUCCESSFUL_CHECKOUT = "/successful-checkout",
  PUBLICATION_PREVIEW = "/publication-previews",
  PROGRAMS_LIST = "/programs-list",
  MY_PROGRAMS = "/my-programs",
  FREE_CHECKOUT = "/free-checkouts",
  PROGRAM_CONTENT = "/program-content",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",
}

export const RoutesWorkingWhenLoggedOut = [
  ROUTES.ABOUT,
  ROUTES.HOME,
  ROUTES.PROGRAMS_LANDING_PAGE,
  ROUTES.LOGIN,
  ROUTES.PUBLICATION_PREVIEW,
  ROUTES.PRIVACY_POLICY,
  ROUTES.PROGRAM_CONTENT,
  ROUTES.TERMS_AND_CONDITIONS,
];

export const signInWithRedirect = (redirect: string) => {
  return `${ROUTES.LOGIN}?redirect=${redirect}`;
};

export default ROUTES;
