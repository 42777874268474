import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { actionAuthLogoutStart } from "../redux/auth/auth.actions";
import { useTranslation } from "../../i18n";
import { I18N_NAMESPACES } from "../i18n_namespaces";
import ConfirmationDialog from "./confirmation-dialog.component";

const Logout = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { t: tCommon } = useTranslation(I18N_NAMESPACES.COMMON);

  return (
    <>
      <ConfirmationDialog
        openModal={modalOpen}
        onConfirmation={() => {
          dispatch(actionAuthLogoutStart());
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
        onClose={() => {
          setModalOpen(false);
        }}
        dialogTitleText={tCommon("Are you sure")}
        dialogContentText={tCommon("Do you want to log out?")}
      />
      <Button
        onClick={() => {
          setModalOpen(true);
        }}
        color="inherit"
      >
        {tCommon("Logout")}
      </Button>
    </>
  );
};

export default Logout;
