import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  LinearProgress,
  useTheme,
  Box,
  useScrollTrigger,
  Slide,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Drawer,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  selectIsAuthenticated,
  selectIsLoggedOut,
  selectUserUid,
  selectIsLoggingOut,
  selectIsLoggingIn,
} from "../redux/auth/auth.selectors";
import { makeStyles } from "@material-ui/core/styles";
import {
  actionAuthLogoutSuccess,
  actionAuthLogoutFailure,
  actionAuthLogoutStart,
} from "../redux/auth/auth.actions";
import { selectIsLoading } from "../redux/loading/loading.selectors";
import ROUTES, {
  RoutesWorkingWhenLoggedOut,
  signInWithRedirect,
} from "../../routes";
import { useRouter } from "next/router";
import firebaseApp from "../../firebase";
import Link from "next/link";
import LanguageSelector from "./LanguageSelector";
import { I18N_NAMESPACES } from "../i18n_namespaces";
import { useTranslation } from "../../i18n";
import ReactResizeDetector, { withResizeDetector } from "react-resize-detector";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ButtonLink from "./ButtonLink";
import { DONOTSKIP_URLS } from "@arnaud-cortisse/donotskip-core";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import Logout from "./logout.component";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 10,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

interface ResponsiveDrawerProps {}
const MyDrawer: React.FC<ResponsiveDrawerProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { t: tCommon } = useTranslation(I18N_NAMESPACES.COMMON);
  const router = useRouter();
  const loggedIn = useSelector(selectIsAuthenticated);

  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItem button key={"Home"} onClick={() => router.push(ROUTES.HOME)}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={tCommon("Home")} />
        </ListItem>
        {loggedIn && (
          <ListItem
            button
            key={"My Programs"}
            onClick={() => router.push(ROUTES.MY_PROGRAMS)}
          >
            <ListItemIcon>
              <ViewColumnIcon />
            </ListItemIcon>
            <ListItemText primary={tCommon("My programs")} />
          </ListItem>
        )}
      </List>
    </div>
  );
};

interface GoBackButtonProps {
  gobackHref: string | undefined;
  gobackHrefAs: string | undefined;
}
const GoBackButton: React.FC<GoBackButtonProps> = ({
  gobackHref,
  gobackHrefAs,
}) => {
  const router = useRouter();

  if (gobackHref && gobackHrefAs) {
    return (
      <IconButton
        component={ButtonLink}
        href={gobackHref}
        hrefAs={gobackHrefAs}
      >
        <ArrowBackIcon style={{ color: "#fff" }} />
      </IconButton>
    );
  } else
    return (
      <IconButton onClick={() => router.back()}>
        <ArrowBackIcon style={{ color: "#fff" }} />
      </IconButton>
    );
};

interface MyAppBarProps {
  getHeightCallback?: (height: number) => void;
  canGoBack?: boolean;
  gobackHref?: string;
  gobackHrefAs?: string;
  title?: string;
  withTitle?: boolean;
}
const MyAppBar: React.FC<MyAppBarProps> = ({
  getHeightCallback,
  canGoBack = false,
  gobackHref,
  gobackHrefAs,
  title,
  withTitle = true,
}) => {
  const router = useRouter();
  const currentPath = router.asPath;
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isLoggedOut = useSelector(selectIsLoggedOut);
  const isLoading = useSelector(selectIsLoading);
  const isLoggingOut = useSelector(selectIsLoggingOut);
  const { t: tCommon } = useTranslation(I18N_NAMESPACES.COMMON);
  const trigger = useScrollTrigger();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
  };

  const container = process.browser ? () => window.document.body : undefined;

  if (!title) {
    title = "DoNotSkip";
  }

  useEffect(() => {
    if (isLoggingOut) {
      const logout = async () => {
        try {
          await firebaseApp.auth().signOut();
          dispatch(actionAuthLogoutSuccess());
          if (
            !RoutesWorkingWhenLoggedOut.find((route: ROUTES) =>
              router.pathname.startsWith(route)
            )
          ) {
            router.push(ROUTES.HOME);
          }
        } catch (error) {
          dispatch(actionAuthLogoutFailure(error));
        }
      };
      logout();
    }
  }, [isLoggingOut]);

  return (
    <div>
      <ReactResizeDetector handleHeight>
        {({ height }: { height: number }) => {
          if (getHeightCallback) {
            getHeightCallback(height);
          }
          return (
            <>
              <Slide appear={false} direction="down" in={!trigger}>
                <AppBar position="fixed">
                  <Toolbar>
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="menu"
                      onClick={handleDrawerToggle}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Box flexGrow={1} display="flex" alignItems="center">
                      {canGoBack && (
                        <GoBackButton
                          gobackHref={gobackHref}
                          gobackHrefAs={gobackHrefAs}
                        />
                      )}
                      {!canGoBack && (
                        <a
                          href={DONOTSKIP_URLS?.DONOTSKIP}
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          <Box display="flex" flexGrow={1} alignItems="center">
                            <img
                              src={"/logo.png"}
                              alt="App icon"
                              style={{ height: 48, width: 48, marginRight: 10 }}
                            />

                            <Typography variant="h6"></Typography>
                          </Box>
                        </a>
                      )}
                    </Box>

                    <LanguageSelector />
                    {isLoggedOut && (
                      <Link href={signInWithRedirect(currentPath)} passHref>
                        <Button color="inherit" component="a">
                          {tCommon("Login")}
                        </Button>
                      </Link>
                    )}
                    {isAuthenticated && <Logout />}
                  </Toolbar>
                  {isLoading && <LinearProgress />}
                </AppBar>
              </Slide>
              <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  <MyDrawer />
                </Drawer>
              </nav>
              <Toolbar />
            </>
          );
        }}
      </ReactResizeDetector>
    </div>
  );
};

export default MyAppBar;
