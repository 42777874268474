import React from "react";
import Link from "next/link";

interface FooterLinkProps {
  title: string;
  href: string;
  hrefAs?: string;
}

const InternalFooterLink: React.FC<FooterLinkProps> = ({
  title,
  href,
  hrefAs,
}) => {
  return (
    <div>
      <Link href={href} as={hrefAs}>
        <a>{title}</a>
      </Link>
    </div>
  );
};

export default InternalFooterLink;
