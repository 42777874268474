import React from "react"
import Link from "next/link";

interface ButtonLinkProps {
  href: string;
  hrefAs?: string;
  className: string;
}
const ButtonLink: React.FC<ButtonLinkProps> = React.forwardRef((props, ref) => {

  const {
    href,
    hrefAs,
    children,
    className,
  } = props

  return <Link href={href} as={hrefAs}>
    <a className={className}>{children}</a>
  </Link>
})

export default ButtonLink;
