import {
  ActionSetLanguagePayload,
  ActionSetLanguage,
  LanguageActionsTypes,
} from "./language.types";

export const actionChangeLanguage = (
  payload: ActionSetLanguagePayload
): ActionSetLanguage => ({
  type: LanguageActionsTypes.SET_LANGUAGE_ACTION_TYPE,
  payload,
});
